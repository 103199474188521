define("alodawpyei-web/transforms/mmdate", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MmdateTransform extends _transform.default {
    deserialize(serialized) {
      var my_date = new Date(serialized);
      //       const result = my_date.toLocaleDateString("en-GB", { // you can use undefined as first argument
      //   year: "numeric",
      //   month: "2-digit",
      //   day: "2-digit",
      // })
      // return result;
      ///return my_date.getDate()+"/"+String(my_date.getMonth()+1).padStart(2, '0')+"/"+my_date.getFullYear();
      return my_date.getFullYear() + "-" + String(my_date.getMonth() + 1).padStart(2, '0') + "-" + my_date.getDate();
    }
    serialize(deserialized) {
      return deserialized;
    }
  }
  _exports.default = MmdateTransform;
});