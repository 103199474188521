define("alodawpyei-web/transforms/mmdate-format", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MmdateFormatTransform extends _transform.default {
    deserialize(serialized) {
      var my_date = new Date(serialized);
      return my_date.getDate() + " " + my_date.toLocaleString('en-US', {
        month: 'short'
      }) + " " + my_date.getFullYear();
    }
    serialize(deserialized) {
      return deserialized;
    }
  }
  _exports.default = MmdateFormatTransform;
});