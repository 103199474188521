define("alodawpyei-web/routes/monastery", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MonasteryRoute extends Ember.Route.extend(_routeMixin.default) {
    model(params) {
      this.store.unloadAll("post");
      params["status"] = "byMonId";
      this.set('ntype', params["ntype"]);
      return Ember.RSVP.hash({
        "model": this.findPaged('post', params)
      });
    }
    setupController(controller, models) {
      controller.set('ntype', this.get('ntype'));
      controller.setProperties(models);
    }
  }
  _exports.default = MonasteryRoute;
});