define("alodawpyei-web/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ApplicationRoute extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        livetext: this.store.findAll("livetext")
      });
    }
    setupController(controller, models) {
      controller.setProperties(models);
    }
  }
  _exports.default = ApplicationRoute;
});