define("alodawpyei-web/routes/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HomeRoute extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        banner: this.store.findAll("banner"),
        donor: this.store.findAll("donor"),
        book: this.store.findAll("book"),
        vpost: this.store.findAll("video-post"),
        monastery: this.store.findAll("monastery")
      });
    }
    setupController(controller, models) {
      controller.setProperties(models);
    }
  }
  _exports.default = HomeRoute;
});