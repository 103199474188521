define("alodawpyei-web/routes/news-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewsDetailRoute extends Ember.Route {
    model(params) {
      return this.store.findRecord("news-detail", params["news_id"]);
    }
  }
  _exports.default = NewsDetailRoute;
});