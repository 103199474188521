define("alodawpyei-web/services/monistery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class MonisteryService extends Ember.Service {
    constructor() {
      super(...arguments);
      _defineProperty(this, "database", {
        "1": "ရန်ကုန်-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "2": "စစ်တွေ-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "3": "မြောက်ဦး-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "4": "ရသေ့တောင်-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "5": "ူးသီးတောင်-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "6": "ဘမောင်တော-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "7": "သံတွဲ-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "8": "ပုဂံ-ညောင်ဦး-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "9": "ကျောက်ပန်းတောင်း-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "10": "မိတ္ထီလာ-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "11": "တောင်နီတောရ-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "12": "ပြင်ဦးလွင်-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "13": "ပမန္တလေး-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "14": "တာချီလိတ်-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "15": "နေပြည်တော်-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "16": "နသင်္ဃန်းကျွန်း-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "17": "ဓမ္မာစရိယ-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "18": "အမေရိကန်နိုင်ငံ-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "19": "အရီဇိုးနား-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "20": "ဆန်ဖရန်စစ္စကို-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "21": "တက္ကဆက်-အလိုတော်ပြည့်ကျောင်းတိုက်",
        "22": "အယ်လ်အေ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      });
      _defineProperty(this, "datalist", [{
        "idx": "1",
        "location": "ရန်ကုန်-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "2",
        "location": "စစ်တွေ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "3",
        "location": "မြောက်ဦး-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "4",
        "location": "ရသေ့တောင်-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "5",
        "location": "ူးသီးတောင်-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "6",
        "location": "ဘမောင်တော-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "7",
        "location": "သံတွဲ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "8",
        "location": "ပုဂံ-ညောင်ဦး-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "9",
        "location": "ကျောက်ပန်းတောင်း-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "10",
        "location": "မိတ္ထီလာ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "11",
        "location": "တောင်နီတောရ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "12",
        "location": "ပြင်ဦးလွင်-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "13",
        "location": "ပမန္တလေး-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "14",
        "location": "တာချီလိတ်-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "15",
        "location": "နေပြည်တော်-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "16",
        "location": "နသင်္ဃန်းကျွန်း-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "17",
        "location": "ဓမ္မာစရိယ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "18",
        "location": "အမေရိကန်နိုင်ငံ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "19",
        "location": "အရီဇိုးနား-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "20",
        "location": "ဆန်ဖရန်စစ္စကို-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "21",
        "location": "တက္ကဆက်-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }, {
        "idx": "22",
        "location": "အယ်လ်အေ-အလိုတော်ပြည့်ကျောင်းတိုက်"
      }]);
    }
  }
  _exports.default = MonisteryService;
});