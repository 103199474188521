define("alodawpyei-web/components/similar-news", ["exports", "@glimmer/component", "alodawpyei-web/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  <ul class="list-unstyled mb-0">
      {{#each getSimilarNews as |data index|}}
                        <li class="border-bottom pb-3 mb-3" {{action "goToDetail" data.id}}>
                          <h4 class="h6 mb-2">
                            <a href="#">{{data.title}}</a>
                          </h4>
                          <div class="d-flex align-items-center text-muted pt-1">
                            <div class="fs-xs border-end pe-3 me-3">{{moment-from-now data.publishdate}}</div>
  
                          </div>
                        </li>
  {{/each}}
                      </ul>
                      <script type="text/javascript">
                      window.scrollTo({ top: 0, behavior: 'auto' });
                      </script>
  
  */
  {
    "id": "3U0VAIxi",
    "block": "{\"symbols\":[\"data\",\"index\",\"&default\"],\"statements\":[[18,3,null],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list-unstyled mb-0\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                      \"],[11,\"li\"],[24,0,\"border-bottom pb-3 mb-3\"],[4,[38,0],[[32,0],\"goToDetail\",[32,1,[\"id\"]]],null],[12],[2,\"\\n                        \"],[10,\"h4\"],[14,0,\"h6 mb-2\"],[12],[2,\"\\n                          \"],[10,\"a\"],[14,6,\"#\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n                        \"],[13],[2,\"\\n                        \"],[10,\"div\"],[14,0,\"d-flex align-items-center text-muted pt-1\"],[12],[2,\"\\n                          \"],[10,\"div\"],[14,0,\"fs-xs border-end pe-3 me-3\"],[12],[1,[30,[36,1],[[32,1,[\"publishdate\"]]],null]],[13],[2,\"\\n\\n                        \"],[13],[2,\"\\n                      \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"                    \"],[13],[2,\"\\n                    \"],[10,\"script\"],[14,4,\"text/javascript\"],[12],[2,\"\\n                    window.scrollTo({ top: 0, behavior: 'auto' });\\n                    \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"moment-from-now\",\"getSimilarNews\",\"-track-array\",\"each\"]}",
    "moduleName": "alodawpyei-web/components/similar-news.hbs"
  });
  if (_environment.default.environment === 'development') {
    var host = _environment.default.localHost;
  } else if (_environment.default.environment === 'production') {
    var host = _environment.default.remoteHost;
  }
  let SimilarNewsComponent = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed, _dec5 = Ember._action, (_class = class SimilarNewsComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "infinity", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
    }
    get getSimilarNews() {
      if (this.args.newstype == "activities") return this.infinity.model('activity-post', {
        perPage: 5
      });else if (this.args.newstype == "vnews") return this.infinity.model('video-post', {
        perPage: 5
      });else return this.infinity.model('photo-post', {
        perPage: 5
      });
    }
    goToDetail(id) {
      this.router.transitionTo("news-detail", id);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "infinity", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getSimilarNews", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "getSimilarNews"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToDetail", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "goToDetail"), _class.prototype)), _class));
  _exports.default = SimilarNewsComponent;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SimilarNewsComponent);
});