define("alodawpyei-web/controllers/news-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let NewsListController = (_dec = Ember._action, _dec2 = Ember._action, (_class = class NewsListController extends Ember.Controller {
    goToDetail(id) {
      this.transitionToRoute("news-detail", id);
    }
    onChangeNews(status) {
      this.transitionToRoute("news-list", status);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "goToDetail", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "goToDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeNews", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeNews"), _class.prototype)), _class));
  _exports.default = NewsListController;
});