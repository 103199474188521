define("alodawpyei-web/router", ["exports", "alodawpyei-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('home', {
      path: '/'
    });
    this.route('book');
    this.route('donation-list');
    this.route('contact');
    this.route('news-detail', {
      path: 'detail/:news_id'
    });
    this.route('news-list');
    this.route('loading');
    this.route('news-list', {
      path: 'news-list/:ntype'
    });
    this.route('biography');
    this.route('history');
    this.route('donate');
    //this.route('monastery');
    this.route('monastery', {
      path: 'monastery/:_id'
    });
    this.route('alodawpray');
    this.route('rnswo');
    this.route('payment-done');
    this.route('website-founder');
    this.route('light-up');
    this.route('lightup-first');
    this.route('lightup-second');
    this.route('pray-one');
    this.route('pray-two');
  });
});