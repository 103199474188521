define("alodawpyei-web/routes/donation-list", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DonationListRoute extends Ember.Route.extend(_routeMixin.default) {
    model(params) {
      this.store.unloadAll("donor");
      return this.findPaged('donor', params);
    }
  }
  _exports.default = DonationListRoute;
});