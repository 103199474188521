define("alodawpyei-web/templates/payment-done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RqtI+C+5",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center pt-5 pb-3 mt-auto\"],[12],[2,\"\\n            \"],[10,\"h3\"],[14,0,\"mb-4\"],[12],[2,\"လှူဒါန်းမှုပြီးဆုံးပါပြီ အလိုတော်ပြည့်ပါစေ\"],[13],[2,\"\\n          \"],[3,\" Parallax gfx (Light version) \"],[2,\"\\n          \"],[10,\"div\"],[14,0,\"parallax mx-auto d-dark-mode-none\"],[14,5,\"max-width: 350px; transform: translate3d(0px, 0px, 0px) rotate(0.0001deg); transform-style: preserve-3d; backface-visibility: hidden; pointer-events: none;\"],[12],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"parallax-layer\"],[14,\"data-depth\",\"-0.15\"],[14,5,\"transform: translate3d(8.4px, -2.1px, 0px); transform-style: preserve-3d; backface-visibility: hidden; position: relative; display: block; left: 0px; top: 0px;\"],[12],[2,\"\\n              \"],[10,\"img\"],[14,\"src\",\"../assets/img/alodaw.png\"],[14,\"alt\",\"Layer\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"text-center mt-4 \"],[12],[2,\"\\n            \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"news-list\",\"home\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"button\"],[14,0,\"btn btn-success my-1 me-1\"],[14,4,\"button\"],[12],[2,\"go to home\"],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alodawpyei-web/templates/payment-done.hbs"
  });
  _exports.default = _default;
});