define("alodawpyei-web/controllers/donate", ["exports", "ember-validity-modifier"], function (_exports, _emberValidityModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DonateController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class DonateController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "donor_name", _descriptor, this);
      _initializerDefineProperty(this, "amount", _descriptor2, this);
      _initializerDefineProperty(this, "phone", _descriptor3, this);
      _initializerDefineProperty(this, "description", _descriptor4, this);
    }
    async validateAndSubmit(event) {
      var _that = this;
      var payment = {
        name: this.donor_name,
        amount: this.amount,
        phone: this.phone,
        description: this.description
      };
      let {
        target: form
      } = event;
      event.preventDefault();
      await (0, _emberValidityModifier.validate)(...form.elements);
      if (form.reportValidity()) {
        let newDonor = this.store.createRecord("payment", payment);
        newDonor.save().then(response => {
          console.log(response);
          _that.donor_name = "";
          _that.amount = 0;
          _that.phone = "";
          _that.description = "";
          //var payload = {"name":data.name,"amount":data.amount,"phone":data.phone,"description":data.description};

          window.location.href = "https://form.dinger.asia/?hashValue=" + response.hashValue + "&payload=" + response.payload;
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "donor_name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "phone", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "validateAndSubmit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "validateAndSubmit"), _class.prototype)), _class));
  _exports.default = DonateController;
});