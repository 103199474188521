define("alodawpyei-web/routes/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ContactRoute extends Ember.Route {
    model() {
      this.store.unloadAll("monastery");
      return this.store.findAll("monastery");
    }
  }
  _exports.default = ContactRoute;
});