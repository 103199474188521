define("alodawpyei-web/routes/news-list", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewsListRoute extends Ember.Route.extend(_routeMixin.default) {
    model(params) {
      this.store.unloadAll("activity-post");
      this.store.unloadAll("video-post");
      this.store.unloadAll("photo-post");
      this.set('ntype', params["ntype"]);
      if (params["ntype"] == "activity") return Ember.RSVP.hash({
        "model": this.findPaged('activity-post', params)
      });else if (params["ntype"] == "vpost") return Ember.RSVP.hash({
        "model": this.findPaged('video-post', params)
      });else if (params["ntype"] == "photo-post") return Ember.RSVP.hash({
        "model": this.findPaged('photo-post', params)
      });else if (params["ntype"] == "tayardaw-post") return Ember.RSVP.hash({
        "model": this.findPaged('tayardaw-post', params)
      });else return Ember.RSVP.hash({
        "model": this.findPaged('post', params)
      });
    }
    setupController(controller, models) {
      controller.set('ntype', this.get('ntype'));
      controller.setProperties(models);
    }
  }
  _exports.default = NewsListRoute;
});